import * as React from "react";

import styled from "@emotion/styled";
import { Switch, Typography } from "@mui/material";

import { THEME } from "../constants";
import { anroidSwitchPropsType } from "../types";

const Android12Switch = styled(Switch)(({...props}) => {
    return ({
        padding: 8,
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: THEME.DOMINANT,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: THEME.ACTIVE_ACCENT,
        },
        '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
            },
            '&::before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    "",
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
            },
            '&::after': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    "",
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                right: 12,
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2,
        },
    })
});

export function AndroidSwitch(props: anroidSwitchPropsType) {
    const {onText, offText, onCheck, checked, ...domProps} = props; 
    const {className, contClassName, style, contStyle, ...rest} = domProps; 

    return (
        <div className={`flex align-center column ${contClassName || ""}`} style={{...contStyle}}>
            <Android12Switch 
                {...rest} 
                sx={{...style}}
                checked={checked}      
                className={`${className || ""}`}
                onChange={(event, checked) => {
                    if (onCheck) onCheck(checked);
                }}
            /> 
            <Typography fontSize={THEME.FONT.PARAGRAPH()} style={{width: "max-content"}}>{checked? onText : offText}</Typography>
        </div>
    )
}